import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/agent/workspace/vOps_provision-documentation_dev/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const FeatureCard = makeShortcode("FeatureCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`We have a multitude of sites using the gatsby theme for both public and internal
sites. Using this theme we’ve been able to propogate features and fixes
throughout the ecosystem quickly and easily.`}</p>
    </PageDescription>
    <h2>{`IBM Design for AI`}</h2>
    <FeatureCard title="IBM Design for AI" actionIcon="arrowRight" href="https://ibm.com/design/ai/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.388888888888886%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAMEBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAB/9oADAMBAAIQAxAAAAHhNUYgK//EABsQAAEEAwAAAAAAAAAAAAAAAAIAAQMzBBES/9oACAEBAAEFAhFyc9dLFsmsX//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8BP//EABkQAAIDAQAAAAAAAAAAAAAAAAABEBExcf/aAAgBAQAGPwKkOsh8HH//xAAbEAACAQUAAAAAAAAAAAAAAAABERAAITFBof/aAAgBAQABPyFTWc0i9Gjjtzv/2gAMAwEAAgADAAAAEHgP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHRABAAEEAwEAAAAAAAAAAAAAAREAECFRMUGRsf/aAAgBAQABPxAYmJQaMtAITMFKFuR2RelZT6Plv//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IBM Design AI",
          "title": "IBM Design AI",
          "src": "/static/b0ccb98ac3e33799d4c6d29b0257fee2/2e753/ai.jpg",
          "srcSet": ["/static/b0ccb98ac3e33799d4c6d29b0257fee2/69549/ai.jpg 288w", "/static/b0ccb98ac3e33799d4c6d29b0257fee2/473e3/ai.jpg 576w", "/static/b0ccb98ac3e33799d4c6d29b0257fee2/2e753/ai.jpg 1152w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h2>{`IBM Design Language`}</h2>
    <FeatureCard title="IBM Design Language" actionIcon="arrowRight" href="https://www.ibm.com/design/language/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.041666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHm48yQgf/EABkQAAIDAQAAAAAAAAAAAAAAAAEQAAIRMf/aAAgBAQABBQLESjLd/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGRAAAgMBAAAAAAAAAAAAAAAAABEBEEEh/9oACAEBAAE/IURPBE5Wqf/aAAwDAQACAAMAAAAQgy//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8QjX//xAAVEQEBAAAAAAAAAAAAAAAAAAAAEf/aAAgBAgEBPxBH/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAEhMUFxsaH/2gAIAQEAAT8QeF6IniyNTCo5su0uH//Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IBM Design Language",
          "title": "IBM Design Language",
          "src": "/static/19be61af47bf986157176b10d55dabf3/2e753/idl.jpg",
          "srcSet": ["/static/19be61af47bf986157176b10d55dabf3/69549/idl.jpg 288w", "/static/19be61af47bf986157176b10d55dabf3/473e3/idl.jpg 576w", "/static/19be61af47bf986157176b10d55dabf3/2e753/idl.jpg 1152w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h2>{`Carbon Design System`}</h2>
    <FeatureCard title="Carbon Design System" actionIcon="arrowRight" href="http://carbondesignsystem.com/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.041666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMEAgX/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAHnbmeNJA//xAAZEAACAwEAAAAAAAAAAAAAAAABMQACESH/2gAIAQEAAQUCndGGVZYX/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFxAAAwEAAAAAAAAAAAAAAAAAECAhMf/aAAgBAQAGPwLRE//EABoQAAMBAAMAAAAAAAAAAAAAAAABETEQIXH/2gAIAQEAAT8hVxFVNwbtYx9NuD//2gAMAwEAAgADAAAAEETP/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPxA//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPxA//8QAHBABAAICAwEAAAAAAAAAAAAAAQARITEQUXGx/9oACAEBAAE/EBhsd7gAFVLaw4gCFLp94BJUpv4QlME//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Carbon Design System",
          "title": "Carbon Design System",
          "src": "/static/45e061a0552771cc08f5fadbf071c384/2e753/cds.jpg",
          "srcSet": ["/static/45e061a0552771cc08f5fadbf071c384/69549/cds.jpg 288w", "/static/45e061a0552771cc08f5fadbf071c384/473e3/cds.jpg 576w", "/static/45e061a0552771cc08f5fadbf071c384/2e753/cds.jpg 1152w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h2>{`IBM Design Research`}</h2>
    <FeatureCard title="IBM Design Research" actionIcon="arrowRight" href="https://ibm.com/design/research/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.041666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAIBAwX/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAc9q3zYAP//EABUQAQEAAAAAAAAAAAAAAAAAABAB/9oACAEBAAEFAmv/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAUEAEAAAAAAAAAAAAAAAAAAAAg/9oACAEBAAY/Al//xAAcEAACAgIDAAAAAAAAAAAAAAAAATFBEBEhUZH/2gAIAQEAAT8huh8jW3C8IHWP/9oADAMBAAIAAwAAABAo7//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8QP//EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQIBAT8QP//EAB0QAAIBBAMAAAAAAAAAAAAAAAABESExQXFRYYH/2gAIAQEAAT8QavUFPA7TvRWMGZF3Qm48CP/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IBM Design Research",
          "title": "IBM Design Research",
          "src": "/static/0bcf848d527de8ac7d7b6581d9a069ce/2e753/idr.jpg",
          "srcSet": ["/static/0bcf848d527de8ac7d7b6581d9a069ce/69549/idr.jpg 288w", "/static/0bcf848d527de8ac7d7b6581d9a069ce/473e3/idr.jpg 576w", "/static/0bcf848d527de8ac7d7b6581d9a069ce/2e753/idr.jpg 1152w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </FeatureCard>
    <h2>{`IBM Design Event`}</h2>
    <FeatureCard title="IBM Design Event" actionIcon="arrowRight" href="https://ibm.com/design/event/" color="dark" mdxType="FeatureCard">
      <span {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1152px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "51.041666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMBAv/aAAwDAQACEAMQAAABwm+BjB5Nf//EABsQAAICAwEAAAAAAAAAAAAAAAACAQMREjIz/9oACAEBAAEFAiMy+rWCj90+X//EABURAQEAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/Aaf/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPwEJ/8QAGRABAQADAQAAAAAAAAAAAAAAAQACEDER/9oACAEBAAY/AuXozkcnRf/EABkQAAMBAQEAAAAAAAAAAAAAAAABESExQf/aAAgBAQABPyGq+B3XczdcE5S6ijFUCTOdEXE//9oADAMBAAIAAwAAABAUD//EABYRAQEBAAAAAAAAAAAAAAAAAAEAMf/aAAgBAwEBPxBQjGX/xAAXEQEBAQEAAAAAAAAAAAAAAAABABEh/9oACAECAQE/EARJXeX/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEhMUFRcWH/2gAIAQEAAT8QbaKjhC5d4Vv7NXqA610oAesRYDjqAaBVkY4ZoLTx9Z//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "IBM Design Research",
          "title": "IBM Design Research",
          "src": "/static/1f3c09fc0e21daf38d0535f0293e4f48/2e753/ide.jpg",
          "srcSet": ["/static/1f3c09fc0e21daf38d0535f0293e4f48/69549/ide.jpg 288w", "/static/1f3c09fc0e21daf38d0535f0293e4f48/473e3/ide.jpg 576w", "/static/1f3c09fc0e21daf38d0535f0293e4f48/2e753/ide.jpg 1152w"],
          "sizes": "(max-width: 1152px) 100vw, 1152px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>
    </FeatureCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      